import themeHelpers from "@gotamedia/fluffy/utils/theme/helpers"
import styled, { css } from "styled-components";

const AdPortalWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    `

const Wrapper = styled.div`
    ${themeHelpers.isNotSmallDevice(css`
        position: relative;
    `)}
    `
    
const HeaderContainer = styled.div`
    display: flex;
    padding: 12px 0px 12px 32px;
    margin: 0 auto;
    max-width: 1600px;
    width: 100%;
    ${themeHelpers.isNotLargeDevice(css`
        padding: 12px 16px;
    `)}
    @media screen and (max-width: 1300px) {
        padding: 12px 16px;
     }
`

const BrandColorBanner = styled.div<{ color: string}>`
     height: 4px;
     align-self: stretch;
     background: ${({color }) => color}; 
`

const HeaderBanner = styled.div`
     height: 1px;
     align-self: stretch;
     background: var(--general-grey-4-dadad-8, #DADAD8);
`

const HeaderWrapper = styled.div`
`

const ContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 1600px;
`

const FooterWrapper = styled.div`
    margin: 0 auto;
    max-width: 1600px;
`
export { 
    HeaderContainer, 
    AdPortalWrapper, 
    ContentWrapper, 
    FooterWrapper, 
    BrandColorBanner, 
    HeaderWrapper, 
    HeaderBanner, 
    Wrapper 
}