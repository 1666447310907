import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'

import ErrorPage from '~/components/ErrorPage'
import BaseDocument from '~/components/BaseDocument'

import type * as Types from './types'
import { useLocation } from '@remix-run/react'
import { isClient } from '~/utils/helpers'

const ErrorBoundary: Types.ErrorBoundaryComponent = ({ error }) => {
    const location = useLocation()
    const scope = useScope()
    const app = useApp()

    const errorData = {
        url: location?.pathname,
        scopeId: scope?.id,
        appName: app?.path,
        method: 'UNKNOWN',
        headers: {},
        statusCode: 'UNKNOWN',
        error: {
            name: error.name,
            message: error.message,
            stack: error.stack?.split('\n').map((str) => str.trim()).slice(1)
        }
    }

    if (isClient) {
        console.error(errorData)
    } else {
        if (process.env.AWS_ENV === 'local') {
            console.error(errorData)
        } else {
            console.error(JSON.stringify(errorData))
        }
    }

    return (
        <BaseDocument
            head={(
                <title>
                    {'Oops!'}
                </title>
            )}
            body={(
                <ErrorPage
                    status={error.message}
                    text={error.stack}
                />
            )}
        />
    )
}

export default ErrorBoundary