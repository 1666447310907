import { useCallback } from 'react'
import {
    push as pushTrackingEvent,
    TrackingEventNames,
    TrackingEventContentTypes
} from '@gotamedia/tracking'
import {
    onReady,
    hasConsent,
    Purposes
} from '@gotamedia/cmp'

import { Cookies } from '~/constants'

import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'
import useRouteContext from '~/hooks/useRouteContext'

import type * as Types from './types'

const debugging = true

const useTracking: Types.UseTracking = () => {
    const app = useApp()
    const scope = useScope()

    const {
        NODE_ENV,
        AWS_ENV,
        APP_VERSION,
        GTM_TRACKING_ID
    } = useRouteContext()

    const canRun = (
        debugging ||
        !!(NODE_ENV !== 'development' && GTM_TRACKING_ID)
    )

    const send: Types.TrackingEventHandler = useCallback((trackingParams) => {
        onReady(() => {
            const consents = hasConsent([Purposes.CookiesForAnalysisAndDevelopment])

            if (consents[Purposes.CookiesForAnalysisAndDevelopment] && canRun && trackingParams) {
                pushTrackingEvent({
                    name: TrackingEventNames.PageView,
                    cookies: {
                        clientId: Cookies.CLIENT_ID,
                        sessionId: Cookies.USER_SESSION_ID
                    },
                    system: {
                        brand: scope.name,
                        brandCode: scope.newspaper,
                        name: "Meeseeks",
                        version: APP_VERSION
                    },
                    isProduction: AWS_ENV === 'prod',
                    isLoggedIn: null,
                    isSubscriber: null,
                    content: {
                        isLocked: false,
                        userHasAccess: true,
                        id: trackingParams.id,
                        section: [app.name, trackingParams.section],
                        system: {
                            name: 'Townport'
                        },
                        title: trackingParams.title || window.document.title,
                        type: TrackingEventContentTypes.Start
                    },
                    extendedEvent: {
                        scopeId: scope.newspaper
                    }
                })
            }
        })
    }, [
        app.name,
        canRun,
        scope,
        AWS_ENV,
        APP_VERSION
    ])

    return {
        canRun: canRun,
        send: send
    }
}

export default useTracking