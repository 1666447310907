import useApp from '~/hooks/useApp'
import useMeta from '~/hooks/useMeta'
import useScope from '~/hooks/useScope'
import useHasMatch from '~/hooks/useHasMatch'
import useSearchParamValues from '~/hooks/useSearchParamValues'

import AppContent from '~/components/AppContent'
import AppFooter from '~/components/AppFooter'
import AppHeader from '~/components/AppHeader'
import NavigationBar from '~/components/NavigationBar'
import ScopeLogo from '~/components/ScopeLogo'
import TitleLink from '~/components/TitleLink'
import CookiesLinks from '~/components/CookiesLinks'

import * as Styled from './style'
import type * as Types from './types'

const MarketGuide: Types.MarketGuideComponent = () => {
    const app = useApp()
    const scope = useScope()
    const meta = useMeta()

    const { embedded } = useSearchParamValues(['embedded'])
    const isMatchingWidgetRoute = useHasMatch('routes/$scopeId/widget')

    const renderContentOnly = embedded === 'true' || isMatchingWidgetRoute

    const contactInfo = scope.contactInfo ? scope.contactInfo : app.contactInfo

    return (
        renderContentOnly ? (
            <AppContent />
        ) : (
            <>
                <AppHeader>
                    <ScopeLogo />
                    <TitleLink />
                </AppHeader>
                <NavigationBar />
                
                <AppContent />

                <AppFooter>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <ScopeLogo bright/>
                        </Styled.Flex>
                    </Styled.FlexRow>

                    <Styled.FlexRow>
                        <Styled.Flex>
                            <Styled.Headline>{'Om Marknadsguiden'}</Styled.Headline>
                            <Styled.Text>{`Marknadsguiden är en webbaserad katalogtjänst med visionen om att vara det bästa sökverktyget för att hitta lokal handel, tjänster och service. Varje månad publiceras också annonser från företagen i ${scope.name} Tidning.`}</Styled.Text>
                            <Styled.Text>{`Vill du synas lite extra här? Vi ger företag möjlighet till en egen presentationssida med bilder, karta och kontaktinformation. Där visas också dina tidningsannonser under sju dagar från införandet. Kontakta oss så berättar vi mer.`}</Styled.Text>
                        </Styled.Flex>
                        
                        <Styled.Flex>
                            <Styled.Headline>{'Kontakta oss'}</Styled.Headline>
                            {
                                meta.address && (
                                    <Styled.Wrapper>
                                        <Styled.Text>{'Adress: '}{meta.address.name}</Styled.Text>
                                        <Styled.Text>{`${meta.address.postalAddress}, ${meta.address.zipcode} ${meta.address.city}`}</Styled.Text>
                                    </Styled.Wrapper>
                                )
                            }
                            {
                                meta.contact.name && (
                                    <Styled.Wrapper>
                                        {
                                            meta.contact.name && (
                                                <Styled.Headline>{meta.contact.name}</Styled.Headline>
                                            )
                                        }

                                        {
                                            meta.contact.phone && (
                                                <Styled.Link href={`tel:${meta.contact.phone}`}>
                                                    {`Telefon: ${meta.contact.phone}`}
                                                </Styled.Link>
                                            )
                                        }

                                        {
                                            meta.contact.email && (
                                                <Styled.Link href={`mailto:${meta.contact.email}`}>
                                                    {`E-post: ${meta.contact.email}`}
                                                </Styled.Link>
                                            )
                                        }
                                    </Styled.Wrapper>
                                )
                            }

                            <CookiesLinks />
                        </Styled.Flex>

                        <Styled.Flex>
                            <Styled.Headline>{'Ansvarig'}</Styled.Headline>

                            {
                                contactInfo.name && (
                                    <Styled.Text>{contactInfo.name}</Styled.Text>
                                )
                            }

                            {
                                contactInfo.phone && (
                                    <Styled.Link href={`tel:${contactInfo.phone}`}>
                                        {`Telefon: ${contactInfo.phone}`}
                                    </Styled.Link>
                                )
                            }

                            {
                                contactInfo.email && (
                                    <Styled.Link href={`mailto:${contactInfo.email}`}>
                                        {`E-post: ${contactInfo.email}`}
                                    </Styled.Link>
                                )
                            }
                        </Styled.Flex>
                    </Styled.FlexRow>
                </AppFooter>
            </>
        )
    )
}

export default MarketGuide