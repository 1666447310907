import useIsRoute from '~/hooks/useIsRoute'

const useIsWidgetRoute = () => {
    const isSharedWidgetRoute = useIsRoute('routes/widget')
    const isFiraWidgetRoute = useIsRoute('routes/fira/widget')
    const isMinnasWidgetRoute = useIsRoute('routes/minnas/widget')

    return (
        isSharedWidgetRoute ||
        isFiraWidgetRoute ||
        isMinnasWidgetRoute
    )
}

export default useIsWidgetRoute