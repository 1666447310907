import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Anchor = styled.a`
    margin: auto auto auto 0;
`

const Image = styled.img`
    width: 200px;
    height: 40px;
    margin: auto 0;

    ${themeHelpers.isSmallDevice(css`
        margin: auto 0;
        width: 170px;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    `)}
`

export {
    Anchor,
    Image
}