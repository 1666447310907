import useMeta from '~/hooks/useMeta'
import useSearchParamValues from '~/hooks/useSearchParamValues'
import useHasMatch from '~/hooks/useHasMatch'

import AppHeader from '~/components/AppHeader'
import ScopeLogo from '~/components/ScopeLogo'
import AppContent from '~/components/AppContent'
import AppFooter from '~/components/AppFooter'
import NavigationBar from '~/components/NavigationBar'
import TitleLink from '~/components/TitleLink'
import CookiesLinks from '~/components/CookiesLinks'

import * as Styled from './style'
import type * as Types from './types'

const ChurchEvent: Types.ChurchEventComponent = () => {
    const meta = useMeta()

    const { embedded } = useSearchParamValues(['embedded'])

    const isMatchingWidgetRoute = useHasMatch('routes/$scopeId/widget')
    const renderContentOnly = embedded === 'true' || isMatchingWidgetRoute

    return (
        renderContentOnly ? (
            <AppContent />
        ) : (
            <>
                <AppHeader>
                    <ScopeLogo />
                    <TitleLink />
                </AppHeader>
                <NavigationBar />
                
                <AppContent />

                <AppFooter>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <ScopeLogo bright/>
                        </Styled.Flex>
                    </Styled.FlexRow>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <Styled.Headline>
                                {'Kontakta oss'}
                            </Styled.Headline>
                        
                            {
                                meta.customerService && (
                                    <Styled.Wrapper>
                                        <Styled.Link href={`tel:${meta.customerService.phone}`}>
                                            {'Kundtjänst: '}{meta.customerService.phone}
                                        </Styled.Link>
                                    </Styled.Wrapper>
                                )
                            }

                            <CookiesLinks />
                        </Styled.Flex>
                    </Styled.FlexRow>
                </AppFooter>
            </>
        )
    )
}

export default ChurchEvent