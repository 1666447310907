import { useMemo } from 'react'

import merge from 'lodash/merge'
import useLazyRef from '@gotamedia/fluffy/useLazyRef'

import useSearchParamValues from '../useSearchParamValues'

import type * as Types from './types'
import useScope from '../useScope'

const useTheme: Types.useTheme = () => {
    const scope = useScope()
    const { theme: stringifiedTheme } = useSearchParamValues(['theme'])

    const themeRef = useLazyRef(() => {
        return {
            colors: {
                brand: scope?.brandColor || '#507748'
            }
        }
    })

    const theme = useMemo(() => {
        let theme = themeRef.current!

        if (typeof stringifiedTheme === 'string' && stringifiedTheme.length) {
            try {
                const themeValue = JSON.parse(Buffer.from(stringifiedTheme, 'base64').toString())
                theme = merge(theme, themeValue)
            } catch(error) {
                // IGNORE
            }
        }

        return theme
    }, [themeRef, stringifiedTheme])

    return theme
}

export default useTheme