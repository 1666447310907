import * as Styled from './style'

import type * as Types from './types'

const AppFooter: Types.AppFooterComponent = ({ children }) => {
    return (
        <Styled.Wrapper>
            <Styled.InnerWrapper>
                {children}
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default AppFooter