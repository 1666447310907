import useRouteData from '~/hooks/useRouteData'

import * as Styled from './style'
import type * as Types from './types'
import type { RootLoaderData } from '~/types'

const AppLogo: Types.AppLogoComponent = (props) => {
    const { scope: {
        logos,
        logosBright
    } } = useRouteData<RootLoaderData>('root')

    const { bright } = props

    const imgs = bright ? logosBright : logos;

    return (
        <>
            {
                imgs.map(logo => {
                    return (
                        <Styled.Anchor
                            key={logo.url}
                            target={'_blank'}
                            rel={'noreferrer'}
                            href={logo.url}
                        >
                            <Styled.Image
                                src={logo.src}
                                alt={'Newspaper logo'}
                            />
                        </Styled.Anchor>
                    )
                })
            }
        </>
    )
}

export default AppLogo