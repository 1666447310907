import useApp from '~/hooks/useApp'
import useHasMatch from '~/hooks/useHasMatch'
import useMeta from '~/hooks/useMeta'
import useScope from '~/hooks/useScope'

import AppContent from '~/components/AppContent'
import AppFooter from '~/components/AppFooter'
import AppHeader from '~/components/AppHeader'
import NavigationBar from '~/components/NavigationBar'
import ScopeLogo from '~/components/ScopeLogo'
import TitleLink from '~/components/TitleLink'

import * as Styled from './style'
import type * as Types from './types'

const Family: Types.FamilyComponent = () => {
    const app = useApp()
    const scope = useScope()
    const meta = useMeta()

    const isMatchingWidgetCelebrationRoute = useHasMatch('routes/fira/widget')
    const isMatchingWidgetMemorialRoute = useHasMatch('routes/minnas/widget')

    const renderContentOnly = isMatchingWidgetCelebrationRoute || isMatchingWidgetMemorialRoute

    const contactInfo = scope.contactInfo ? scope.contactInfo : app.contactInfo

    return (
        renderContentOnly ? (
            <AppContent />
        ) : (
            <>
                <AppHeader>
                    <ScopeLogo />
                    <TitleLink />
                </AppHeader>
                <NavigationBar />

                <AppContent />

                <AppFooter>
                    <Styled.FlexRow>
                        <Styled.Flex>

                            <ScopeLogo bright/>
                        </Styled.Flex>
                    </Styled.FlexRow>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <Styled.Headline>{'Om Familjeannonser'}</Styled.Headline>
                            <Styled.Text>{`Familj är en rikstäckande annonsplats med tusentals annonser inom firaannonsering. När du lägger in en annons här så publiceras den kan du som tillval välja att publicera den på Familj, på tidningssajt och i ${scope.name} Tidning. På det sättet når annonsen ut till en bred målgrupp både på nätet och i tidningen. `}</Styled.Text>
                        </Styled.Flex>
                        <Styled.Flex>
                            <Styled.Headline>{'Kontakta oss'}</Styled.Headline>
                        
                            {
                                meta.address && (
                                    <Styled.Wrapper>
                                        <Styled.Text>{'Adress: '}{meta.address.name}</Styled.Text>
                                        <Styled.Text>{`${meta.address.postalAddress}, ${meta.address.zipcode} ${meta.address.city}`}</Styled.Text>
                                        {(scope.id === "sydostran" || scope.id === "blt") && (<Styled.Text>{'Växel: '}{meta.address.phone}</Styled.Text>)}
                                    </Styled.Wrapper>
                                )
                            }

                            <Styled.CookiesLinks />
                        </Styled.Flex>
                    </Styled.FlexRow>

                    <Styled.FlexRow>
                        <Styled.Flex>
                            <Styled.Headline></Styled.Headline>
                            <Styled.Text>{'Annonsen är sökbar på ett 80-tal dagstidningssajter och den publiceras i den tidning som du angett.'}</Styled.Text>
                        </Styled.Flex>

                        <Styled.Flex>
                            {(scope.id !== "blt" && scope.id !== "sydostran") &&
                            <>
                                {
                                    meta.contact && (
                                        <Styled.Wrapper>
                                            {
                                                meta.contact.name && (
                                                    <Styled.Headline>{meta.contact.name}</Styled.Headline>
                                                )
                                            }

                                            {
                                                meta.contact.phone && (
                                                    <Styled.Link href={`tel:${meta.contact.phone}`}>
                                                        {`Telefon: ${meta.contact.phone}`}
                                                    </Styled.Link>
                                                )
                                            }

                                            {
                                                meta.contact.email && (
                                                    <Styled.Link href={`mailto:${meta.contact.email}`}>
                                                        {`E-post: ${meta.contact.email}`}
                                                    </Styled.Link>
                                                )
                                            }
                                        </Styled.Wrapper>
                                    )
                                }
                            
                                {
                                    contactInfo.name && (
                                        <Styled.Headline>{contactInfo.name}</Styled.Headline>
                                    )
                                }

                                {
                                    contactInfo.phone && (
                                        <Styled.Link href={`tel:${contactInfo.phone}`}>
                                            {`Telefon: ${contactInfo.phone}`}
                                        </Styled.Link>
                                    )
                                }

                                {
                                    contactInfo.email && (
                                        <Styled.Link href={`mailto:${contactInfo.email}`}>
                                            {`E-post: ${contactInfo.email}`}
                                        </Styled.Link>
                                    )
                                }
                            </>
                            }
                        </Styled.Flex>
                    </Styled.FlexRow>
                </AppFooter>
            </>
        )
    )
}

export default Family