import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.footer`
    width: 100%;
    display: flex;
    margin: auto 0 0 0;
    background-color: ${({ theme }) => theme.colors.grey[0]};

    * {
        color: #ffffff;
    }
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
    `)}
`

const Flex = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;

    &:first-child {
        flex: 2;
    }

    &:last-child {
        flex: 1;
    }

    p, h2 {
        white-space: pre-line;
        margin-bottom: 5px;
    }
`

export {
    Wrapper,
    InnerWrapper,
    Flex
}