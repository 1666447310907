import useMeta from '~/hooks/useMeta'
import useSearchParamValues from '~/hooks/useSearchParamValues'

import AppContent from '~/components/AppContent'
import AppFooter from '~/components/AppFooter'
import AppHeader from '~/components/AppHeader'
import ScopeLogo from '~/components/ScopeLogo'
import CookiesLinks from '~/components/CookiesLinks'

import useHasMatch from '~/hooks/useHasMatch'
import NavigationBar from '../NavigationBar'
import TitleLink from '../TitleLink'
import * as Styled from './style'
import type * as Types from './types'

const Jobs: Types.JobsComponent = () => {
    const meta = useMeta()

    const { embedded } = useSearchParamValues(['embedded'])

    const isMatchingWidgetRoute = useHasMatch('routes/$scopeId/widget')

    const renderContentOnly = embedded === 'true' || isMatchingWidgetRoute

    return (
        renderContentOnly ? (
            <AppContent />
        ) : (
            <>
                <AppHeader>
                    <ScopeLogo />
                    <TitleLink />
                </AppHeader>
                <NavigationBar />

                <AppContent />

                <AppFooter>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <ScopeLogo bright/>
                        </Styled.Flex>
                    </Styled.FlexRow>
                    <Styled.FlexRow>
                        <Styled.Flex>
                            <Styled.Headline>{'Om Jobb'}</Styled.Headline>
                            <Styled.Text>{`Jobb är en jobbportal som ingår i paketeringar tillsammans med starka lokala mediehusprodukter där annonsörer och arbetssökande möts.`}</Styled.Text>
                        </Styled.Flex>
                        <Styled.Flex>
                            <Styled.Headline>{'Kontakta oss'}</Styled.Headline>
                        
                            {
                                meta.address && (
                                    <Styled.Wrapper>
                                        <Styled.Text>{'Adress: '}{meta.address.name}</Styled.Text>
                                        <Styled.Text>{`${meta.address.postalAddress}, ${meta.address.zipcode} ${meta.address.city}`}</Styled.Text>
                                    </Styled.Wrapper>
                                )
                            }

                            <CookiesLinks />

                            {
                                meta.contact.name && (
                                    <Styled.Wrapper>
                                        {
                                            meta.contact.name && (
                                                <Styled.Headline>{meta.contact.name}</Styled.Headline>
                                            )
                                        }

                                        {
                                            meta.contact.phone && (
                                                <Styled.Link href={`tel:${meta.contact.phone}`}>
                                                    {`Telefon: ${meta.contact.phone}`}
                                                </Styled.Link>
                                            )
                                        }

                                        {
                                            meta.contact.email && (
                                                <Styled.Link href={`mailto:${meta.contact.email}`}>
                                                    {`E-post: ${meta.contact.email}`}
                                                </Styled.Link>
                                            )
                                        }
                                    </Styled.Wrapper>
                                )
                            }
                        </Styled.Flex>

                    </Styled.FlexRow>
                </AppFooter>
            </>
        )
    )
}

export default Jobs