import useFooter from "~/hooks/useFooter"

import ScopeLogo from "~/components/ScopeLogo"
import CookiesLinks from '~/components/CookiesLinks'

import * as Styled from "./style"
import type * as Types from "./types"

const AdPortalFooter: Types.FooterComponent = () => {
    const footer = useFooter()

    return (
        <Styled.Footer>
            <Styled.Flex>
                <Styled.LogoWrapper>
                    <ScopeLogo bright />
                </Styled.LogoWrapper>
            </Styled.Flex>
            <Styled.FooterColumnWrapper>
                <Styled.FlexColumn>
                    <Styled.FlexRow>
                        <Styled.BoldText>{footer.publisher.titleChief}</Styled.BoldText>
                        <Styled.Link href={`mailto:${footer.publisher.email}`}>
                            <Styled.Text>{footer.publisher.name}</Styled.Text>
                        </Styled.Link>
                    </Styled.FlexRow>
                    <Styled.Section>
                        <Styled.FlexRow>
                            <Styled.BoldText>{footer.adressTitle}</Styled.BoldText>
                            <Styled.Text>
                                {footer.address.postalAddress}, {footer.address.zipcode} {footer.address.city}</Styled.Text>
                        </Styled.FlexRow>
                        <Styled.FlexRow>
                            <Styled.BoldText>{footer.contactTitle}</Styled.BoldText>
                            <Styled.Link href={`tel:${footer.contact.phone}`}>
                                <Styled.Text>{footer.contact.phone}</Styled.Text>
                            </Styled.Link>
                        </Styled.FlexRow>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.copyrightTitle}</Styled.BoldText>
                        <Styled.Text>{footer.copyright}</Styled.Text>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.contact.editors.editorsTitle}</Styled.BoldText>
                        <Styled.FlexRow>
                            <Styled.Text>{footer.contact.editors.emailLabel}</Styled.Text>
                            <Styled.Link
                                href={`mailto:${footer.contact.editors.email}`}
                            >
                                <Styled.Text>{footer.contact.editors.email}</Styled.Text>
                            </Styled.Link>
                        </Styled.FlexRow>
                        <Styled.FlexRow>
                            <Styled.Text>{footer.contact.editors.phoneLabel}</Styled.Text>
                            <Styled.Link
                                href={`tel:${footer.contact.editors.phone}`}>
                                <Styled.Text>{footer.contact.editors.phone}</Styled.Text>
                            </Styled.Link>
                        </Styled.FlexRow>
                    </Styled.Section>
                </Styled.FlexColumn>

                <Styled.FlexColumn>
                    <Styled.Section>
                        <Styled.BoldText>{footer.socialMedia.socialMediaTitle}</Styled.BoldText>
                        <Styled.SocialMediaWrapper>
                            <Styled.SocialMediaLink
                                target="_blank"
                                href={footer.socialMedia.facebook}
                            >
                                <Styled.SocialMediaIcon src="/icons/facebook-icon.svg" alt="Facebook Logo" />
                                {footer.socialMedia.facebookTitle}
                            </Styled.SocialMediaLink>
                            <Styled.SocialMediaLink
                                target="_blank"
                                href={footer.socialMedia.twitter}
                            >
                                <Styled.SocialMediaIcon src="/icons/twitter-icon.svg" alt="Twitter Logo" />
                                {footer.socialMedia.twitterTitle}
                            </Styled.SocialMediaLink>
                            <Styled.SocialMediaLink
                                target="_blank"
                                href={footer.socialMedia.instagram}
                            >
                                <Styled.SocialMediaIcon src="/icons/instagram-icon.svg" alt="Instagram Logo" />
                                {footer.socialMedia.instagramTitle}
                            </Styled.SocialMediaLink>
                        </Styled.SocialMediaWrapper>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.apps.appsTitle}</Styled.BoldText>
                        <Styled.AppsWrapper>
                            <Styled.Text>{footer.apps.minApp.infoText}</Styled.Text>
                        </Styled.AppsWrapper>
                        <Styled.AppsWrapper>
                            <Styled.Link
                                href={footer.apps.minApp.appStoreLink}>
                                {footer.apps.appStoreTitle}
                            </Styled.Link>
                            <Styled.Link
                                href={footer.apps.minApp.googlePlayLink}>
                                {footer.apps.googlePlayTitle}
                            </Styled.Link>
                        </Styled.AppsWrapper>
                    </Styled.Section>
                    {footer.apps.minAdditionalApp &&
                        <Styled.Section>
                            <Styled.AppsWrapper>
                                <Styled.Text>{footer.apps.minAdditionalApp.infoText}</Styled.Text>
                            </Styled.AppsWrapper>
                            <Styled.AppsWrapper>
                                <Styled.Link
                                    href={footer.apps.minAdditionalApp.appStoreLink}
                                >
                                    {footer.apps.appStoreTitle}
                                </Styled.Link>
                                <Styled.Link
                                    href={footer.apps.minAdditionalApp.googlePlayLink}>
                                    {footer.apps.googlePlayTitle}
                                </Styled.Link>
                            </Styled.AppsWrapper>
                        </Styled.Section>}
                    {footer.apps.eApp &&
                        <Styled.Section>
                            <Styled.AppsWrapper>
                                <Styled.Text>{footer.apps.eApp.infoText}</Styled.Text>
                            </Styled.AppsWrapper>
                            <Styled.AppsWrapper>
                                <Styled.Link
                                    href={footer.apps.eApp.appStoreLink}
                                >
                                    {footer.apps.appStoreTitle}
                                </Styled.Link>
                                <Styled.Link
                                    href={footer.apps.eApp.googlePlayLink}
                                >
                                    {footer.apps.googlePlayTitle}
                                </Styled.Link>
                            </Styled.AppsWrapper>
                        </Styled.Section>}
                </Styled.FlexColumn>
                <Styled.FlexColumn>
                    <Styled.Section>
                        <Styled.BoldText>{footer.myPagesTitle}</Styled.BoldText>
                        {footer.linksMyPages.map((myPagesLink) =>
                            <Styled.Link
                                key={myPagesLink.text}
                                href={myPagesLink.link}
                            >
                                {myPagesLink.text}
                            </Styled.Link>
                        )}
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.aboutTitle}</Styled.BoldText>
                        {footer.linksAbout.map((aboutLink) =>
                            <Styled.Link
                                key={aboutLink.text}
                                href={aboutLink.link}
                            >
                                {aboutLink.text}
                            </Styled.Link>
                        )}

                        <CookiesLinks />
                    </Styled.Section>
                </Styled.FlexColumn>
                <Styled.FlexColumn>
                    <Styled.Section>
                        <Styled.BoldText>{footer.gotamedia.linksInfoText}</Styled.BoldText>
                        <Styled.Links>
                            {footer.gotamedia.links.map((brand) =>
                                <Styled.Link
                                    key={brand.id}
                                    href={brand.link}
                                >
                                    {brand.text}
                                </Styled.Link>
                            )}
                        </Styled.Links>
                    </Styled.Section>
                </Styled.FlexColumn>
            </Styled.FooterColumnWrapper>
        </Styled.Footer>
    )
}

export default AdPortalFooter