import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'
import { Link as UnstyledLink } from '@remix-run/react'

const Wrapper = styled.header`
    width: 100%;
    display: flex;
    min-height: 44px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`


const Anchor = styled.a`
    margin: auto 0;
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.black};
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    ${themeHelpers.isSmallDevice(css`
        margin-right: 0;
    `)}

    &:last-of-type {
        margin-right: 0;
    }
`

const Link =  styled(UnstyledLink)<{$active?: boolean}>`
    margin-right: 15px;
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
    padding: 0 4px;
    height: 100%;
    display: flex;
    align-items: center;

    ${themeHelpers.isSmallDevice(css`
        margin-right: 10px;
    `)}

    ${({ $active }) => $active && css`
        box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.colors.brand};
    `}

    &:last-of-type {
        margin-right: 0;
    }
`

export {
    Link,
    Anchor,
    InnerWrapper,
    Wrapper
}
