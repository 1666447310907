import { useMemo } from 'react'

import { ItemType } from '~/services/resolvers/item/types'

import { isClient } from '~/utils/helpers'

import useRouteData from '~/hooks/useRouteData'

import type { RootLoaderData } from '~/types'
import type { CompanyPayload} from '~/modules/marketGuide/types'
import type { JobPayload } from '~/modules/jobs/types'
import type { ChurchEventAdsPayload } from '~/modules/churchevent/types'
import type { FamilyAdsPayload } from '~/modules/family/types'

import * as Constants from './constants'

const useTrackingPayload = () => {
    const sharedRouteData = useRouteData<RootLoaderData<CompanyPayload | JobPayload | ChurchEventAdsPayload>>('routes/$name/$id')
    const celebrationRouteData = useRouteData<RootLoaderData<FamilyAdsPayload>>('routes/fira/$id')
    const memorialRouteData = useRouteData<RootLoaderData<FamilyAdsPayload>>('routes/minnas/$id')

    const trackingData = useMemo(() => {
        const base = {
            id: '',
            title: '',
            section: ''
        }

        if (
            sharedRouteData ||
            celebrationRouteData ||
            memorialRouteData
        ) {
            const routeData = [
                sharedRouteData,
                celebrationRouteData,
                memorialRouteData
            ].find((item) => item)

            switch (routeData?._type) {
                case ItemType.Company: {
                    const _routeData = routeData as RootLoaderData<CompanyPayload>
                    base.id = _routeData?.payload?.uuid
                    base.title = _routeData?.payload?.name
                    base.section = Constants.TrackingEventSection.MarketPage
                    break
                }

                case ItemType.Job: {
                    const _routeData = routeData as RootLoaderData<JobPayload>
                    base.id = _routeData?.payload.uuid
                    base.title = _routeData?.payload.companyName
                    base.section = Constants.TrackingEventSection.JobAd
                    break
                }

                case ItemType.ChurchEventAd: {
                    const _routeData = routeData as RootLoaderData<ChurchEventAdsPayload>
                    base.id = _routeData?.payload?.data_list[0]?.uid
                    base.title = _routeData?.payload?.data_list[0]?.title
                    base.section = Constants.TrackingEventSection.ChurchEvent
                    break
                }

                case ItemType.BirthdayAd: {
                    const _routeData = routeData as RootLoaderData<FamilyAdsPayload>
                    base.id = _routeData?.payload?.ads[0]?.uuid
                    base.title = _routeData?.payload?.ads[0]?.title
                    base.section = Constants.TrackingEventSection.CelebrationAd
                    break
                }

                case ItemType.MemorialAd: {
                    const _routeData = routeData as RootLoaderData<FamilyAdsPayload>
                    base.id = _routeData?.payload?.ads[0]?.uuid
                    base.title = _routeData?.payload?.ads[0]?.title
                    base.section = Constants.TrackingEventSection.MemorialAd
                    break
                }
            }
        }

        const data = {
            id: base.id || 'search-page',
            section: base.section,
            title: base.title || (isClient ? document.title : Constants.TrackingEventSection.SearchPage)
        }

        return data
    }, [
        sharedRouteData,
        celebrationRouteData,
        memorialRouteData,
    ])

    return trackingData
}

export default useTrackingPayload
