import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    ${themeHelpers.isNotSmallDevice(css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `)}
`

const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`

export {
    Wrapper,
    InnerWrapper
}