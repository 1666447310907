import { useEffect } from 'react'
import { useLocation } from '@remix-run/react'
import useIsomorphicLayoutEffect from '@gotamedia/fluffy/useIsomorphicLayoutEffect'
import { GTM } from '@gotamedia/tracking'

import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'
import useIsWidgetRoute from '~/hooks/useIsWidgetRoute'
import useTrackingPayload from '~/hooks/useTrackingPayload'
import useTracking from '~/hooks/useTracking'

const Tracking = () => {
    const app = useApp()
    const scope = useScope()

    const location = useLocation()

    const isWidgetRoute = useIsWidgetRoute()

    const trackingPayload = useTrackingPayload()

    const {
        canRun,
        send: sendTrackingEvent
    } = useTracking()

    useIsomorphicLayoutEffect(() => {
        GTM.initiate()
    }, [])

    useEffect(() => {
        if (canRun && !isWidgetRoute) {
            sendTrackingEvent({
                id: trackingPayload.id,
                title: trackingPayload.title,
                section: trackingPayload.section
            })
        }
    }, [
        canRun,
        isWidgetRoute,
        sendTrackingEvent,
        location,
        trackingPayload
    ])

    return (
        canRun ? (
            <script
                suppressHydrationWarning={true}
                dangerouslySetInnerHTML={{
                    __html: `
                        (function (_windoww, _document, _script, _layer) {
                            _windoww[_layer] = _windoww[_layer] || [];
                            _windoww[_layer].push({ event: 'gtm.init', app_type: '${app.id}' });
                            _windoww[_layer].push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });

                            var firstScript = _document.getElementsByTagName(_script)[0];
                            var newScript = _document.createElement(_script), dl = _layer != 'dataLayer' ? '&l=' + _layer : '';

                            newScript.async = true;
                            newScript.src = 'https://tracking.${scope.id}.se/gtm.js?id=${"GTM-WHHFXWX"}';
                            
                            firstScript.parentNode.insertBefore(newScript, firstScript);
                        })(window, document, 'script', 'dataLayer');
                    `
                }}
            />
        ) : (
            null
        )
    )
}

export default Tracking