import { open as cmpOpen } from '@gotamedia/cmp'

import * as Styled from './style'
import type * as Types from './types'

const CookiesLinks: Types.CookiesLinks = (props) => {
    return (
        <Styled.Wrapper {...props}>
            <Styled.FakeLink onClick={cmpOpen.notice}>
                {'Hantera kakor'}
            </Styled.FakeLink>

            <Styled.Link
                target={'_blank'}
                href={'https://kundcenter.gotamedia.se/kakpolicy/'}
            >
                {'Kakpolicy'}
            </Styled.Link>
        </Styled.Wrapper>
    )
}

export default CookiesLinks