import * as Styled from './style'
import type * as Types from './types'

const AppHeader: Types.AppHeaderComponent = ({ children }) => {    
    return (
        <Styled.Wrapper>
            <Styled.InnerWrapper>
                {children}
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default AppHeader