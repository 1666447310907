
import useScope from '~/hooks/useScope'

import * as Styled from './style'
import type * as Types from './types'

const TitleLink: Types.TitleLinkComponent = () => {
    const { url, name } = useScope()

    return (
        <Styled.Wrapper>
            <Styled.Anchor
                key={url}
                target={'_blank'}
                rel={'noreferrer'}
                href={url}
            >
                {`Till ${name}`}
            </Styled.Anchor>
        </Styled.Wrapper>
    )
}

export default TitleLink