import { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle<{ fontFamily?: string }>`
    #didomi-host {
        .didomi {
            &-components {
                &-radio {
                    &__option {
                        &--selected {
                            display: flex !important;
    
                            svg {
                                margin: auto 5px auto auto !important;
                            }
    
                            span {
                                margin: auto auto auto 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
`

export {
    Global
}