import { Outlet, useLoaderData } from '@remix-run/react'

import { AvailableApps } from '~/config/apps'
import throwAppError from '~/utils/throwAppError'

import BaseDocument from '~/components/BaseDocument'
import ChurchEvent from '~/components/ChurchEvent'
import Family from '~/components/Family'
import Jobs from '~/components/Jobs'
import MarketGuide from '~/components/MarketGuide'
import ConsentWrapper from '~/components/ConsentWrapper'

import type { RootLoaderData } from '~/types'
import AdPortal from '../AdPortal/AdPortal'
import * as Styled from './style'
import type * as Types from './types'

const AppResolver: Types.AppResolverComponent = () => {
    const {
        app,
        isError
    } = useLoaderData<RootLoaderData>()

    if (!isError && (!app || !app.id)) {
        throwAppError()
    }

    const getApp = () => {
        if (isError) {
            return (
                <Outlet />
            )
        } else {
            switch(app.id) {
                case AvailableApps.MarketGuide:
                    return (
                        <MarketGuide />
                    )
    
                case AvailableApps.Jobs:
                    return (
                        <Jobs />
                    )

                case AvailableApps.Family:
                    return (
                        <Family />
                    )

                case AvailableApps.ChurchEvent:
                    return (
                        <ChurchEvent />
                    )
                    
                case AvailableApps.AdPortal:
                    return (
                        <AdPortal />
                    )
    
                default:
                    throwAppError()
            }
        }
    }

    return (
        <BaseDocument>
            <Styled.Wrapper>
                <Styled.InnerWrapper>
                    <ConsentWrapper>
                        {getApp()}
                    </ConsentWrapper>
                </Styled.InnerWrapper>
            </Styled.Wrapper>
        </BaseDocument>
    )
}

export default AppResolver