import { themeHelpers } from '@gotamedia/fluffy/theme'
import styled, { css } from 'styled-components'

const Flex = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;

    &:first-child {
        flex: 2;
    }

    &:last-child {
        flex: 1;
    }

    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
        padding-bottom: 0;
    `)}

`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
    `)}
`

const Link = styled.a`
    font-family: 'Sanomat Sans Text';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.grey[3]};
`

const Headline = styled.h2`
    font-family: 'Sanomat Sans Text';
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
    color: ${({ theme }) => theme.colors.grey[4]};
`

const Wrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;

    &:first-child {
        flex: 2;
    }

    &:last-child {
        flex: 1;
    }

    p, h2 {
        white-space: pre-line;
        margin-bottom: 5px;
    }
`

export {
    Link,
    Headline,
    FlexRow,
    Flex,
    Wrapper
}