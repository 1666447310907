import { themeHelpers } from '@gotamedia/fluffy/theme'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
    margin: auto auto auto 0;
    flex-grow: 1;
    text-align: right;
    ${themeHelpers.isSmallDevice(css`
        margin: auto auto 10px auto;
    `)}
`

const Anchor = styled.a`
    margin: auto 0;
    margin-right: 15px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.link};

    ${themeHelpers.isSmallDevice(css`
        margin-right: 0;
    `)}

    &:last-of-type {
        margin-right: 0;
    }
`


export {
    Anchor,
    Wrapper
}
