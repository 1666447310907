import type { Footer, FooterItem } from "~/config/types"
import useScope from "../useScope"

import footerConfig from '~/config/footer.json'


const useFooter = (): FooterItem => {
    const scope = useScope()
    
    const footer: FooterItem = footerConfig[scope.id as keyof Footer]
    return footer
}

export default useFooter