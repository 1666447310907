import { useMemo } from 'react'
import { useLocation } from '@remix-run/react'

import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'

import * as Styled from './style'
import type * as Types from './types'

const NavigationBar: Types.NavigationBarComponent = () => {
    const location = useLocation()
    
    const { navigation: appNavigation = [] } = useApp()
    const { navigation: scopeNavigation = [] } = useScope()

    const navigation = useMemo(() => {
        return [
            ...appNavigation,
            ...scopeNavigation
        ]
    }, [appNavigation, scopeNavigation])

    return (
        <Styled.Wrapper>
            <Styled.InnerWrapper>
                {
                    navigation.map((navigation) => {
                        if (navigation.href.includes('https')) {
                            return (
                                <Styled.Anchor
                                    key={navigation.href}
                                    target={'_blank'}
                                    href={navigation.href}
                                >
                                    {navigation.name}
                                </Styled.Anchor>
                            )
                        } else {
                            return (
                                <Styled.Link
                                    key={navigation.href}
                                    $active={location.pathname.includes(navigation.href.replace(/\//g, ''))}
                                    to={navigation.href}
                                >
                                    {navigation.name}
                                </Styled.Link>
                            )
                        }
                    })
                }
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default NavigationBar