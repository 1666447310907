import { Outlet } from '@remix-run/react'

import type * as Types from './types'

const AppContent: Types.AppContentComponent = () => {
    return (
        <Outlet />
    )
}

export default AppContent