import { useCatch } from '@remix-run/react'

import BaseDocument from '~/components/BaseDocument'
import ErrorPage from '~/components/ErrorPage'

import type * as Types from './types'

const CatchBoundary: Types.CatchBoundaryComponent = (props) => {
    const {
        status,
        statusText
    } = props

    const caught = useCatch()
    
    return (
        <BaseDocument
            head={(
                <title>
                    {'Oops!'}
                </title>
            )}
            body={(
                <ErrorPage
                    status={caught.status || status}
                    text={caught.data || caught.statusText || statusText}
                />
            )}
        />
    )
}

export default CatchBoundary