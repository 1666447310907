{
    "meraosterlen": {
        "copyrightTitle": "© MeraÖsterlen",
        "copyright": "Allt material på meraosterlen.se är skyddat enligt upphovsrättslagen",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Lars Mohlin",
            "email": "lars.mohlin@ystadsallehanda.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/Mera-%C3%96sterlen-100110588982350/",
            "facebookId": "100110588982350",
            "instagram": "https://www.instagram.com/meraosterlen/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "advertise": "Annonsera: ",
        "advertiseLink": "foretagsannons@ystadsallehanda.se",
        "contact": {
            "email": "redaktion@olandsbladet.se",
            "phone": "0411-55 78 00",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "red@osterlenmagasinet.se:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "red@osterlenmagasinet.se"
            }
        },
        "providerTitle": "Tillhandahållare av sajten:",
        "provider": "Ystads Allehanda"
    }
}