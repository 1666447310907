import { useMatches } from '@remix-run/react'

const useIsRoute = (id: string): boolean => {
    const routes = useMatches()

    const currentRoute = routes[routes.length - 1]

    if (currentRoute && currentRoute.id === id) {
        return true
    } else {
        return false
    }
}

export default useIsRoute