{
    "barometern": {
        "address": {
            "name": "Barometern-OT",
            "postalAddress": "Västra Sjögatan 7",
            "zipcode": "392 32",
            "city": "Kalmar"
        },
        "copyrightTitle": "© Barometern",
        "copyright": "Allt material på Barometern är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Anders Enström",
            "email": "anders.enstrom@barometern.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://www.facebook.com/barometern",
            "facebookId": "690124984414480",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/barran_nyheter",
            "twitterHandle": "@barran_nyheter",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/barometern/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0480-591 00",
            "link": "https://www.barometern.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Barometern:",
                        "field": "phone"
                    },
                    {
                        "label": "Oskarshamns-Tidningen:",
                        "field": "phoneOT"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "tips@barometern.se",
                "phoneLabel": "Tipstelefon: ",
                "phone": "0480-592 80"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinBarometern",
                "infoText": "Här laddar du ner appen MinBarometern som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/se/app/minbarometern/id1373780935",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinBarometern"
            },
            "minAdditionalApp": {
                "name": "MinOT",
                "infoText": "Här laddar du ner appen MinOT som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/us/app/minot/id1373783525",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinOT&hl=sv"
            },
            "eApp": {
                "infoText": "Här laddar du ner eBarometern-OT (E-tidningen)",
                "link": "https://etidning.barometern.se/623/barometern/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.barometern.se/623/latest-issue",
                "name": "eBarometern-OT",
                "paperName": "Barometern",
                "appStoreLink": "https://apps.apple.com/se/app/ebar-ot/id643687629",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.barometern.reader&hl=sv"
            },
            "additionalEApp": {
                "link": "https://etidning.barometern.se/665/oskarshamnstidningen/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.barometern.se/665/latest-issue",
                "paperName": "Oskarshamnstidningen"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.barometern.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.barometern.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.barometern.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.barometern.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.barometern.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om Barometern och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "blt": {
        "address": {
            "name": "BLT Sydöstran",
            "postalAddress": "Ronnebygatan 26-28",
            "zipcode": "371 89",
            "city": "Karlskrona"
        },
        "copyrightTitle": "© Blekinge Läns Tidning",
        "copyright": "Allt material på blt.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Mimmi Karlsson-Bernfalk",
            "email": "mimmi.karlsson-bernfalk@blt.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://www.facebook.com/blt.se/",
            "facebookId": "690124984414480",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/blt_se/",
            "twitterHandle": "@barran_nyheter",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/blekingelanstidning/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0455-77 000",
            "link": "https://www.blt.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Blekinge Läns Tidning:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "77177@blt.se",
                "phoneLabel": "Tipstelefon: ",
                "phone": "0455-77 177"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinBLT",
                "infoText": "Här laddar du ner appen MinBLT som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/se/app/minblt/id1371335882",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinBLT"
            },
            "eApp": {
                "infoText": "Här laddar du ner eBLT (E-tidningen)",
                "link": "https://etidning.blt.se/483/blt/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.blt.se/483/latest-issue",
                "name": "eBLT",
                "paperName": "BLT",
                "appStoreLink": "https://apps.apple.com/se/app/eblt/id643704577",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.blekingelanstidning.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.blt.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.blt.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.blt.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.blt.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.blt.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om BLT och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "bt": {
        "address": {
            "name": "Borås Tidning",
            "postalAddress": "Allégatan 67",
            "zipcode": "501 85",
            "city": "Borås"
        },
        "copyrightTitle": "© Borås Tidning",
        "copyright": "Allt material på bt.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Stefan Eklund",
            "email": "stefan.eklund@bt.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://facebook.com/borastidning/",
            "facebookId": "615033568623687",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/boras_tidning/",
            "twitterHandle": "@boras_tidning",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/borastidning/?hl=sv/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "033-700 07 00",
            "link": "https://www.bt.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Borås Tidning:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "tipsa@bt.se",
                "phone": "033-700 07 77",
                "phoneLabel": "Tipstelefon: "
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "allAps": "MinBT",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinBT",
                "infoText": "Här laddar du ner appen MinBT som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://itunes.apple.com/se/app/minbt/id1351181432?mt=8",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinBT&hl=en_US"
            },
            "eApp": {
                "infoText": "Här laddar du ner eBT (E-tidningen)",
                "link": "https://etidning.bt.se/201/bt/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.bt.se/201/latest-issue",
                "name": "eBT",
                "paperName": "BT",
                "appStoreLink": "https://itunes.apple.com/se/app/ebt/id583901079?mt=8",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.boraastidning.reader&hl=sv"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.bt.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.bt.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.bt.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.bt.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.bt.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om Borås Tidning och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "kristianstadsbladet": {
        "address": {
            "name": "kristianstadsbladet",
            "postalAddress": "Västra Vallgatan 2",
            "zipcode": "291 84",
            "city": "Kristianstad"
        },
        "copyrightTitle": "© Kristianstadsbladet",
        "copyright": "Allt material på kristianstadsbladet.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Jörgen Svensson",
            "email": "jorgen.svensson@kristianstadsbladet.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://www.facebook.com/kristianstadsbladet/",
            "facebookId": "1501435900124986",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/kbladet/",
            "twitterHandle": "@kbladet",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/kristianstadsbladet/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "044-18 55 00",
            "link": "https://www.kristianstadsbladet.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Kristianstadsbladet:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "nyheter@kristianstadsbladet.se",
                "phone": "044-18 55 00",
                "phoneLabel": "Tipstelefon: "
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "allAps": "MinKb",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinKb",
                "infoText": "Här laddar du ner appen MinKb som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/se/app/minkb/id1377218854/",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinKb/"
            },
            "eApp": {
                "infoText": "Här laddar du ner eKB (E-tidningen)",
                "link": "",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.kristianstadsbladet.se/653/latest-issue",
                "name": "eKB",
                "paperName": "KB",
                "appStoreLink": "https://apps.apple.com/se/app/ekb/id587378625/",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.kristianstadsbladet.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.kristianstadsbladet.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.kristianstadsbladet.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.kristianstadsbladet.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.bt.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.kristianstadsbladet.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om Kristianstadsbladet och Gota Media",
                "link": "https://www.kristianstadsbladet.se/mina-sidor/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "nsk": {
        "address": {
            "name": "Norra Skåne",
            "postalAddress": "Östergatan 5",
            "zipcode": "281 30",
            "city": "Hässleholm"
        },
        "copyrightTitle": "© Norra Skåne",
        "copyright": "Allt material på nsk.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Jörgen Svensson",
            "email": "jorgen.svensson@nsk.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "twitterTitle": "X",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/NorraSkane",
            "facebookId": "137526386304496",
            "twitter": "https://twitter.com/NSKRedaktion",
            "twitterHandle": "@NSKRedaktion",
            "instagram": "https://www.instagram.com/norraskane/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0451-74 51 00",
            "link": "nyhetschefen@nsk.se",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Norra Skåne:",
                        "field": "phoneCustomerCenter"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "nyhetschefen@nsk.se",
                "phoneLabel": "Tipstelefon: ",
                "phone": "0451-74 51 00"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "infoText": "Här laddar du ner appen MinNSk som förbättrar din nyhetsbevakning.",
                "name": "MinNSK",
                "appStoreLink": "https://apps.apple.com/se/app/norra-sk%C3%A5ne/id516544243?l=en",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.nsk&hl=sv&gl=US"
            },
            "eApp": {
                "infoText": "Här laddar du ner eNSK (E-tidningen)",
                "link": "https://etidning.nsk.se/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.nsk.se/937/latest-issue",
                "name": "eNSK",
                "paperName": "NSK",
                "appStoreLink": "https://apps.apple.com/se/app/ensk/id465640376",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.norraskaane.ereader&hl=sv&gl=US"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.nsk.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.nsk.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.nsk.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.nsk.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.nsk.se/prenumerera/"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om NSK och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "smp": {
        "address": {
            "name": "Smålandsposten",
            "postalAddress": "Linnégatan 2",
            "zipcode": "351 70",
            "city": "Växjö"
        },
        "copyrightTitle": "© Smålandsposten",
        "copyright": "Allt material på smp.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Kristina Bingström",
            "email": "kristina.bingstrom@smp.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://www.facebook.com/smalandsposten/",
            "facebookId": "781937541887552",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/smalandsposten",
            "twitterHandle": "@smalandsposten",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/smalandsposten/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0470-77 05 00",
            "link": "https://www.smp.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Smålandsposten:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "nyheter@smp.se",
                "phone": "0470-77 06 07",
                "phoneLabel": "Tipstelefon: "
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "allAps": "MinSMP",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinSMP",
                "infoText": "Här laddar du ner appen MinSMP som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/se/app/minsmp/id1373785440",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinSMP"
            },
            "eApp": {
                "infoText": "Här laddar du ner eSMP (E-tidningen)",
                "link": "",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.smp.se/669/latest-issue",
                "name": "eSMP",
                "paperName": "SMP",
                "appStoreLink": "https://apps.apple.com/se/app/esmp/id644168080",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.smaalandsposten.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.smp.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.smp.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.smp.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.smp.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.kristianstadsbladet.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om Kristianstadsbladet och Gota Media",
                "link": "https://www.smp.se/mina-sidor/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "sydostran": {
        "address": {
            "name": "Sydöstran",
            "postalAddress": "Ronnebygatan 26",
            "zipcode": "371 89",
            "city": "Karlskrona"
        },
        "copyrightTitle": "© Sydöstran",
        "copyright": "Allt material på sydostran.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": " Anders Nilsson",
            "email": "anders.nilsson@sydostran.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "twitterTitle": "X",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/Sydostran/",
            "facebookId": "1556169451262902",
            "twitter": "https://twitter.com/Sydostran",
            "twitterHandle": "@Sydostran",
            "instagram": "https://www.instagram.com/sydostran/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0455-33 46 00",
            "link": "https://www.sydostran.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Sydöstran:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "phoneLabel": "Tipstelefon: ",
                "email": "redaktion@sydostran.se",
                "phone": "0455-334618"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "infoText": "Här laddar du ner appen MinSydöstran som förbättrar din nyhetsbevakning.",
                "name": "MinSydöstran",
                "appStoreLink": "https://apps.apple.com/se/app/minsyd%C3%B6stran/id1371337398",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinSydostran"
            },
            "eApp": {
                "infoText": "Här laddar du ner eSydöstran (E-tidningen)",
                "link": "https://etidning.sydostran.se/685/sydostran/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.sydostran.se/685/latest-issue",
                "name": "eSydöstran",
                "paperName": "Sydöstran",
                "appStoreLink": "https://apps.apple.com/se/app/esyd%C3%B6stran/id643740177",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.sydostran.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.sydostran.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.sydostran.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.sydostran.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.sydostran.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.sydostran.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om SSD och Gota Media",
                "link": "https://www.sydostran.se/mina-sidor/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "trelleborgsallehanda": {
        "address": {
            "name": "Trelleborgsallehanda",
            "postalAddress": "Hamngatan 9",
            "zipcode": "231 42",
            "city": "Trelleborg"
        },
        "copyrightTitle": "© Trelleborgsallehanda",
        "copyright": "Allt material på trelleborgsallehanda.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Martin Falkenby",
            "email": "martin.falkenby@trelleborgsallehanda.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "facebook": "https://www.facebook.com/trelleborgsallehanda/",
            "facebookId": "325722444274109",
            "twitterTitle": "X",
            "twitter": "https://twitter.com/trelleborg",
            "twitterHandle": "@trelleborg",
            "instagramTitle": "Instagram",
            "instagram": "https://www.instagram.com/trelleborgsallehanda/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0410-545 00",
            "link": "https://www.trelleborgsallehanda.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Trelleborgs Allehanda:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "red@trelleborgsallehanda.se",
                "phone": "0410-545 99",
                "phoneLabel": "Tipstelefon: "
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "allAps": "MinTA",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "name": "MinTA",
                "infoText": "Här laddar du ner appen MinTA som förbättrar din nyhetsbevakning.",
                "appStoreLink": "https://apps.apple.com/se/app/minta/id1377138104",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinTA"
            },
            "eApp": {
                "infoText": "Här laddar du ner eTA (E-tidningen)",
                "link": "",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.trelleborgsallehanda.se/707/latest-issue",
                "name": "eTA",
                "paperName": "Trelleborgs Allehanda",
                "appStoreLink": "https://apps.apple.com/se/app/eta/id587375157",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.trelleborgsallehanda.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.trelleborgsallehanda.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.trelleborgsallehanda.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.trelleborgsallehanda.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.bt.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.trelleborgsallehanda.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om TA och Gota Media",
                "link": "https://www.trelleborgsallehanda.se/mina-sidor/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://www.trelleborgsallehanda.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "ut": {
        "address": {
            "name": "Ulricehamns Tidning",
            "postalAddress": "Bogesundsgatan 7",
            "zipcode": "523 34",
            "city": "Ulricehamn"
        },
        "copyrightTitle": "© Ulricehamns Tidning",
        "copyright": "Allt material på ut.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Henrik Erickson",
            "email": "henrik.erickson@ut.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "twitterTitle": "X",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/ulrtid/",
            "facebookId": "713399155434601",
            "twitter": "https://twitter.com/ulrtid",
            "twitterHandle": "@ulrtid",
            "instagram": "https://www.instagram.com/ulricehamns_tidning/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0321-262 00",
            "link": "https://www.ut.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Ulricehamns Tidning:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "phoneLabel": "Tipstelefon: ",
                "email": "red@ut.se",
                "phone": "0321-262 17"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "infoText": "Här laddar du ner appen MinUT som förbättrar din nyhetsbevakning.",
                "name": "MinUT",
                "appStoreLink": "https://apps.apple.com/se/app/minut/id1381526851",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinUT"
            },
            "eApp": {
                "infoText": "Här laddar du ner eUT (E-tidningen)",
                "link": "https://etidning.ut.se/717/ulricehamns-tidning/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.ut.se/717/latest-issue",
                "name": "eUT",
                "paperName": "Ulricehamns Tidning",
                "appStoreLink": "https://apps.apple.com/se/app/eut/id587370499",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.ulricehamnstidning.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.ut.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.ut.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.ut.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.ut.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.ut.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om UT och Gota Media",
                "link": "https://www.ut.se/mina-sidor/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "ystadsallehanda": {
        "address": {
            "name": "Ystads Allehanda",
            "postalAddress": "Österportstorg 1",
            "zipcode": "271 41",
            "city": "Ystad"
        },
        "copyrightTitle": "© Ystads Allehanda",
        "copyright": "Allt material på ystadsallehanda.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Lars Mohlin",
            "email": "lars.mohlin@ystadsallehanda.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "twitterTitle": "X",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/ystadsallehanda/",
            "facebookId": "728174603930712",
            "twitter": "https://twitter.com/ystad",
            "twitterHandle": "@ystad",
            "instagram": "https://www.instagram.com/ystadsallehanda/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "phone": "0411-55 78 00",
            "link": "https://www.ystadsallehanda.se/tipsa-oss/",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Ystads Allehanda:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "phoneLabel": "Tipstelefon: ",
                "email": "red@ystadsallehanda.se",
                "phone": "0411-55 78 00"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "infoText": "Här laddar du ner appen MinYA som förbättrar din nyhetsbevakning.",
                "name": "MinYA",
                "appStoreLink": "https://apps.apple.com/se/app/minya/id1377190711",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinYA"
            },
            "eApp": {
                "infoText": "Här laddar du ner eYA (E-tidningen)",
                "link": "https://etidning.ystadsallehanda.se/721/ystads-allehanda/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.ystadsallehanda.se/721/latest-issue",
                "name": "eYA",
                "paperName": "Ystads Allehanda",
                "appStoreLink": "https://apps.apple.com/se/app/eya/id587374048",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.ystadsallehanda.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.ystadsallehanda.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.ystadsallehanda.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.ystadsallehanda.se/"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.ystadsallehanda.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.ystadsallehanda.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om Barometern och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    },
    "olandsbladet": {
        "address": {
            "name": "Ölandsbladet",
            "postalAddress": "Kyrkogatan 18",
            "zipcode": "387 31",
            "city": "Borgholm"
        },
        "copyrightTitle": "© Ölandsbladet",
        "copyright": "Allt material på olandsbladet.se är skyddat enligt upphovsrättslagen",
        "adressTitle": "Adress: ",
        "contactTitle": "Kontakt växel/kundcenter: ",
        "publisher": {
            "titleChief": "Ansvarig utgivare: ",
            "name": "Peter Boström ",
            "email": "peter.bostrom@olandsbladet.se"
        },
        "socialMedia": {
            "socialMediaTitle": "Följ oss i sociala medier",
            "facebookTitle": "Facebook",
            "twitterTitle": "X",
            "instagramTitle": "Instagram",
            "facebook": "https://www.facebook.com/Olandsbladet/",
            "facebookId": "1003457063015537",
            "twitter": "https://twitter.com/olandsbladet",
            "twitterHandle": "@olandsbladet",
            "instagram": "https://www.instagram.com/olandsbladet/",
            "title": "Innehåll från {{newspaper_name}}",
            "description": "Läs mer på {{newspaper_name}}"
        },
        "contact": {
            "link": "redaktion@olandsbladet.se",
            "phone": "0485-417 00",
            "customerCenter": {
                "phoneOptions": [
                    {
                        "label": "Ölandsbladet:",
                        "field": "phone"
                    }
                ]
            },
            "editors": {
                "editorsTitle": "Tipsa redaktionen",
                "emailLabel": "E-post: ",
                "email": "redaktion@olandsbladet.se",
                "phoneLabel": "Tipstelefon: ",
                "phone": "0485-417 00",
                "link": "https://www.olandsbladet.se/tipsa-oss/"
            }
        },
        "apps": {
            "appsTitle": "Våra appar",
            "googlePlayTitle": "GooglePlay",
            "appStoreTitle": "AppStore  |  ",
            "minApp": {
                "infoText": "Här laddar du ner appen MinÖlandsbladet som förbättrar din nyhetsbevakning.",
                "name": "MinÖlandsbladet",
                "appStoreLink": "https://apps.apple.com/se/app/min%C3%B6landsbladet/id1388268779",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.gotamedia.MinOlandsbladet"
            },
            "eApp": {
                "infoText": "Här laddar du ner eÖlandsbladet (E-tidningen)",
                "link": "https://etidning.olandsbladet.se/731/olandsbladet/latest/",
                "imgSrc": "https://mediacdn.prenly.com/api/image/etidning.olandsbladet.se/731/latest-issue",
                "name": "eÖlandsbladet",
                "paperName": "Ölandsbladet",
                "appStoreLink": "https://apps.apple.com/se/app/%C3%B6landsbladet/id644150911",
                "googlePlayLink": "https://play.google.com/store/apps/details?id=se.olandsbladet.reader"
            }
        },
        "myPagesTitle": "Mina sidor",
        "linksMyPages": [
            {
                "text": "Mina sidor",
                "link": "https://www.olandsbladet.se/mina-sidor/"
            },
            {
                "text": "Kundcenter",
                "link": "https://www.olandsbladet.se/kundcenter/"
            },
            {
                "text": "Annonsera",
                "link": "https://annonsera.barometern.se"
            },
            {
                "text": "E-tidningen",
                "link": "https://etidning.olandsbladet.se/"
            },
            {
                "text": "Utebliven tidning",
                "link": "http://www.pointlogistik.se/utebliventidning/"
            },
            {
                "text": "Prenumerera",
                "link": "https://www.olandsbladet.se/prenumerera"
            }
        ],
        "aboutTitle": "Om oss",
        "linksAbout": [
            {
                "text": "Om OB och Gota Media",
                "link": "https://www.gotamedia.se/gota-media/om-gota-media/"
            },
            {
                "text": "Prenumerationsvillkor",
                "link": "https://kundcenter.gotamedia.se/villkor"
            },
            {
                "text": "Personuppgiftspolicy",
                "link": "https://kundcenter.gotamedia.se/personuppgiftspolicy/"
            },
            {
                "text": "Kakpolicy",
                "link": "https://kundcenter.gotamedia.se/kakpolicy/"
            }
        ],
        "gotamedia": {
            "linksInfoText": "Gota Medias varumärken täcker mediemarknaden i drygt 40 kommuner i västra och sydöstra Sverige: ",
            "links": [
                {
                    "id": "Barometern Oskarshamns-Tidningen, ",
                    "text": "Barometern Oskarshamns-Tidningen, ",
                    "link": "http://www.barometern.se/"
                },
                {
                    "id": "Blekinge Läns Tidning, ",
                    "text": "Blekinge Läns Tidning, ",
                    "link": "http://www.blt.se/"
                },
                {
                    "id": "Borås Tidning, ",
                    "text": "Borås Tidning, ",
                    "link": "http://www.bt.se/"
                },
                {
                    "id": "Kalmar Läns Tidning, ",
                    "text": "Kalmar Läns Tidning, ",
                    "link": "http://klt.nu/"
                },
                {
                    "id": "Kristianstadsbladet, ",
                    "text": "Kristianstadsbladet, ",
                    "link": "http://www.kristianstadsbladet.se/"
                },
                {
                    "id": "Norra Skåne, ",
                    "text": "Norra Skåne, ",
                    "link": "http://www.nsk.se/"
                },
                {
                    "id": "Smålandsposten, ",
                    "text": "Smålandsposten, ",
                    "link": "http://www.smp.se/"
                },
                {
                    "id": "Trelleborgs Allehanda",
                    "text": "Trelleborgs Allehanda, ",
                    "link": "http://www.trelleborgsallehanda.se/"
                },
                {
                    "id": "Ulricehamns Tidning",
                    "text": "Ulricehamns Tidning, ",
                    "link": "http://www.ut.se/"
                },
                {
                    "id": "Växjöbladet",
                    "text": "Växjöbladet/Kronobergaren, ",
                    "link": "http://www.vaxjobladet.se/"
                },
                {
                    "id": "Ystadsallehanda",
                    "text": "Ystads Allehanda, ",
                    "link": "http://www.ystadsallehanda.se/"
                },
                {
                    "id": "Ölandsbladet,",
                    "text": "Ölandsbladet",
                    "link": "http://www.olandsbladet.se/"
                }
            ]
        }
    }
}