import 'source-map-support/register';

import { json } from '@remix-run/node';

import getApp from '~/utils/getApp';

import metaResolver from '~/services/resolvers/meta';

import getScope from '~/utils/scope';

import type {
  MetaFunction,
  LoaderFunction,
  LinksFunction } from
'@remix-run/node';

import styles from '~/style/global.css';

export const meta: MetaFunction = ({ data, location }) => {
  const metaPayload = metaResolver(data, location);

  return metaPayload;
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/SanomatSans-Light-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/SanomatSans-Regular-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/SanomatSans-Bold-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/SanomatSansText-Regular-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/SanomatSansText-Bold-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'preload',
    as: 'font',
    href: '/fonts/sanomat/Sanomat-Extrabold-Web.woff',
    type: 'font/woff'
  },
  {
    rel: 'manifest',
    href: '/manifest.json'
  },
  {
    rel: 'stylesheet',
    href: styles
  }];

};

export const loader: LoaderFunction = async ({ request, params, context }) => {
  const url = new URL(request.url);

  if (url.pathname.startsWith('/page-not-found')) {
    return json({
      isError: true
    });
  }

  const app = getApp(request.url);

  if (app instanceof Response) {
    return app;
  }

  const scope = getScope(app, request.url);

  if (scope instanceof Response) {
    return scope;
  }

  // if (url.pathname.startsWith('/entry.worker.js')) {
  //     return json({
  //         app: app,
  //         scope: scope,
  //         context: context
  //     })
  // }

  if (scope) {
    return json({
      app: app,
      scope: scope,
      context: context
    });
  }
};

export { default as CatchBoundary } from '~/components/CatchBoundary';

export { default as ErrorBoundary } from '~/components/ErrorBoundary';

export { default } from '~/components/AppResolver';