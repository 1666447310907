import styled, { css } from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`

const linkStyle = css`
    cursor: pointer;
    font-family: 'Sanomat Sans Text';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
    color: ${({ theme }) => theme.colors.grey[3]};

    &:hover {
        text-decoration: underline;
    }
`

const Link = styled.a`
    ${linkStyle};
`

const FakeLink = styled.a`
    ${linkStyle};
`

export {
    Wrapper,
    Link,
    FakeLink
}