import styled from 'styled-components'


const Wrapper = styled.header`
    width: 100%;
    display: flex;
    min-height: 80px;
    background-color: #ffffff;
    border-top: 4px solid ${({ theme }) => theme.colors.brand};
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    position: relative;
`

export {
    Wrapper,
    InnerWrapper
}