import { useEffect } from 'react'

import useViewSize, { VIEW_SIZES } from '../useViewSize'

const useMaintainers = () => {
    const { size } = useViewSize()

    useEffect(() => {
        Object.values(VIEW_SIZES).forEach(viewSize => {
            document.body.classList.remove(viewSize)
        })

        document.body.classList.add(size)
    }, [size])
}

export default useMaintainers