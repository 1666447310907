import { useMemo } from 'react'

import {
    CMP,
    DEFAULT_CONSENT_CONFIG
} from '@gotamedia/cmp'

import cmpConfig from '~/config/cmp.json'

import { Cookies } from '~/constants'

import useScope from '~/hooks/useScope'
import useIsWidgetRoute from '~/hooks/useIsWidgetRoute'

import * as Styled from './style'
import type * as Types from './types'

const COOKIES_TO_KEEP = [
    Cookies.USERNAME,
    Cookies.ACCESS_TOKEN,
    Cookies.REFRESH_TOKEN,
    Cookies.CUSTOMER_NUMBER,
    Cookies.SHARED_REFEESH_TOKEN
]

const ConsentWrapper: Types.ConsentWrapper = ({ children }) => {
    const scope = useScope()

    const isWidgetRoute = useIsWidgetRoute()

    const config = useMemo(() => {
        return {
            ...DEFAULT_CONSENT_CONFIG,
            app: {
                ...DEFAULT_CONSENT_CONFIG.app,
                name: scope.name,
                logoUrl: scope.logo
            },
            theme: {
                linkColor: '#0182C9',
                font: 'Sanomat Sans Text'
            }
        }
    }, [scope.logo, scope.name])

    return (
        <>
            <Styled.Global />

            <CMP
                cleanUpCookies
                config={config}
                apiKey={cmpConfig.apiKey}
                noticeId={cmpConfig.noticeId}
                sdkPath={cmpConfig.sdkPath}
                iabVersion={cmpConfig.iabVersion}
                disabled={isWidgetRoute}
                cookiesToKeep={COOKIES_TO_KEEP}
            >
                {children}
            </CMP>
        </>
    )
}

export default ConsentWrapper
