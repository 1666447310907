import useScope from '~/hooks/useScope/useScope'
import useChatScript from '~/hooks/useChatScript'

import AppContent from '~/components/AppContent'
import ScopeLogo from '~/components/ScopeLogo'
import TitleLink from '~/components/TitleLink/TitleLink'

import AdPortalFooter from './components/footer'
import AdPortalNicheFooter from './components/nicheSiteFooter'

import faqConfig from '~/config/faq.json'

import * as Styled from './style'
import type * as Types from './types'

const AdPortal: Types.AdPortalComponent = () => {
    const scope = useScope()
    
    useChatScript(faqConfig.chat.toolbox)

    return (
        <Styled.AdPortalWrapper>
            <Styled.Wrapper>
                <Styled.BrandColorBanner 
                    color={scope.brandColor} 
                />
                <Styled.HeaderContainer>
                    <ScopeLogo />
                    <TitleLink />
                </Styled.HeaderContainer>
                <Styled.HeaderBanner />
                <AppContent />
                { (scope?.id  === 'meraosterlen') ? 
                    <AdPortalNicheFooter />
                    :
                    <AdPortalFooter />
                }
            </Styled.Wrapper>
        </Styled.AdPortalWrapper>
    )
}

export default AdPortal