import footerConfig from '~/config/nichefooter.json'
import type { NicheFooter, NicheFooterItem } from "~/config/types"

import useScope from "~/hooks/useScope"

import ScopeLogo from "~/components/ScopeLogo"
import CookiesLinks from '~/components/CookiesLinks'

import * as Styled from "./style"
import type * as Types from "./types"

const AdPortalNicheFooter: Types.NicheFooterComponent = () => {
    const scope = useScope()
    
    const footer: NicheFooterItem = footerConfig[scope.id as keyof NicheFooter]
    
    return (
        <Styled.Footer>
            <Styled.Flex>
                <ScopeLogo bright/>  
            </Styled.Flex>
            <Styled.FooterColumnWrapper>
                <Styled.FlexColumn>
                    <Styled.FlexRow>
                        <Styled.BoldText>{footer.publisher.titleChief}</Styled.BoldText>
                        <Styled.Link href={`mailto:${footer.publisher.email}`}>
                            <Styled.Text>{footer.publisher.name}</Styled.Text>
                        </Styled.Link>
                    </Styled.FlexRow>
                    <Styled.Section>
                        <Styled.FlexRow>
                            <Styled.BoldText>{footer.contactTitle}</Styled.BoldText>
                            <Styled.Text>{footer.contact.phone}</Styled.Text>
                        </Styled.FlexRow>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.FlexRow>
                            <Styled.BoldText>{footer.advertise}</Styled.BoldText>
                            <Styled.Text>{footer.advertiseLink}</Styled.Text>
                        </Styled.FlexRow>
                    </Styled.Section>
                </Styled.FlexColumn>
                <Styled.FlexColumn>
                    <Styled.Section>
                        <Styled.BoldText>{footer.socialMedia.socialMediaTitle}</Styled.BoldText>
                        <Styled.SocialMediaWrapper>
                            <Styled.SocialMediaLink
                                target="_blank" 
                                href={footer.socialMedia.facebook}
                            >
                                <Styled.SocialMediaIcon 
                                    src="/icons/facebook-icon.svg"
                                    alt="Facebook Logo" 
                                />
                                {footer.socialMedia.facebookTitle}
                            </Styled.SocialMediaLink>
                            <Styled.SocialMediaLink
                                target="_blank"
                                href={footer.socialMedia.instagram}
                            >
                                <Styled.SocialMediaIcon
                                    src="/icons/instagram-icon.svg"
                                    alt="Instagram Logo" 
                                />
                                {footer.socialMedia.instagramTitle}
                            </Styled.SocialMediaLink>
                        </Styled.SocialMediaWrapper>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.contact.editors.editorsTitle}</Styled.BoldText>
                        <Styled.FlexRow>
                            <Styled.Text>{footer.contact.editors.emailLabel}</Styled.Text>
                            <Styled.Text>{footer.contact.editors.email}</Styled.Text>
                        </Styled.FlexRow>
                    </Styled.Section>
                </Styled.FlexColumn>
                <Styled.FlexColumn>
                    <Styled.Section>
                        <CookiesLinks />                                                                                                                                    
                    </Styled.Section>
                </Styled.FlexColumn>
                <Styled.FlexColumn>
                    <Styled.Section>
                        <Styled.BoldText>{footer.copyrightTitle}</Styled.BoldText>
                        <Styled.Text>{footer.copyright}</Styled.Text>                                                                                                                                      
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.BoldText>{footer.providerTitle}</Styled.BoldText>
                        <Styled.Text>{footer.provider}</Styled.Text>     
                    </Styled.Section>
                </Styled.FlexColumn>
            </Styled.FooterColumnWrapper>
        </Styled.Footer>
    )
}

export default AdPortalNicheFooter