import useRouteData from '../useRouteData'

import type { Context } from '~/config/types'

const useRouteContext = (): Context => {
    const { context } = useRouteData<{ context: Context }>('root')

    return context || {}
}

export default useRouteContext