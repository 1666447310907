import {
    Links,
    LiveReload,
    Meta,
    Scripts,
    ScrollRestoration
} from '@remix-run/react'

import { ThemeProvider } from '@gotamedia/fluffy/ThemeContext'

import { isServer } from '~/utils/helpers'

import useMaintainers from '~/hooks/useMaintainers'
// import useServiceWorker from '~/hooks/useServiceWorker'
import useScope from '~/hooks/useScope'
import useViewSize from '~/hooks/useViewSize'
import useTheme from '~/hooks/useTheme'
import useHasMatch from '~/hooks/useHasMatch'

import Tracking from '~/components/Tracking'

import type * as Types from './types'

const Document: Types.DocumentComponent = (props) => {
    const {
        head,
        body,
        children
    } = props

    useMaintainers()
    // useServiceWorker()

    const theme = useTheme()

    const scope = useScope()
    const { size } = useViewSize()

    const isMatchingWidgetRoute = useHasMatch('routes/$scopeId/widget')
    const isMatchingFiraWidgetRoute = useHasMatch('routes/fira/widget')
    const isMatchingMinnasWidgetRoute = useHasMatch('routes/minnas/widget')

    return (
        <html lang={'sv'}>
            <head>
                <Links />
                
                <Tracking />

                {head}
                
                <meta
                    name={'theme-color'}
                    content={'#ffffff'}
                />

                <Meta />

                <link
                    rel={'icon'}
                    sizes={'16x16'}
                    href={scope?.favicon}
                    type={'image/png'}
                />

                <link
                    rel={'icon'}
                    sizes={'192x192'}
                    href={scope?.favicon_192}
                    type={'image/png'}
                />

                <link
                    rel={'icon'}
                    sizes={'512x512'}
                    href={scope?.favicon_512}
                    type={'image/png'}
                />

                <link
                    rel={'apple-touch-icon'}
                    href={scope?.favicon_512}
                />
                
                {isServer ? '__STYLES__' : null}
            </head>

            <body className={size}>
                <ThemeProvider theme={theme}>
                    {body}
                    {children}
                </ThemeProvider>

                {
                    !isMatchingWidgetRoute && !isMatchingFiraWidgetRoute && !isMatchingMinnasWidgetRoute ? (
                        <ScrollRestoration />
                    ) : (
                        null
                    )
                }

                <Scripts />

                <LiveReload />
            </body>
        </html>
    )
}

export default Document