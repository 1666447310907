import { themeHelpers } from '@gotamedia/fluffy/theme'
import styled, { css } from 'styled-components'

const Footer = styled.div`
    display: flex;
    padding: 40px 64px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background: #2E2A25;
    width: 100%;
    margin-top: auto;

    ${themeHelpers.isNotLargeDevice(css`
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 16px;
    `)}
    @media screen and (max-width: 1300px) {
        flex-direction: column;
        padding: 32px 16px;
        align-items: flex-start;
     }
`
const FooterColumnWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 280px;
    max-width: 1600px;
    ${themeHelpers.isMediumDevice(css`
        max-width: 747px;
        flex-wrap:wrap;
        gap: 157px;
    `)}
    @media screen and (max-width: 1300px){
         max-width: 744px;
            flex-direction: column;
            gap: 48px;
     }
    ${themeHelpers.isNotLargeDevice(css`
        gap: 48px;
        max-width: 375px;
        flex-direction: column;
        gap: 70px;
    `)}
`

const Flex = styled.span`
    max-width: 1600px;
    width: 100%;
`
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 1 0;
    ${themeHelpers.isMediumDevice(css`
        width:100%;
    `)}
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
        padding-bottom: 0;
    `)}
    @media screen and (max-width: 1300px) {
        width:100%;
     }
`

const FlexRow = styled.div`
    display: block;
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
    `)}
`

const Section = styled.div`
    display: flex;
    flex-direction: column;
`
 
const BoldText = styled.p`
    font-family: Sanomat Sans Text;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-right: 2px;
    display: inline;
    color: #DADAD8;
`

const Text = styled.p`
    font-family: Sanomat Sans Text;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: inline;
    color: ${({ theme }) => theme.colors.grey[3]};
`

const Headline = styled.h2`
    font-family: 'Sanomat Sans Text';
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
    margin-right: 2px;
    color: ${({ theme }) => theme.colors.grey[4]};
`
const SocialMediaWrapper = styled.div`
    display: flex;
    flex: 1 1 0;
    gap: 32px;
    margin-top: 10px;
`
const AppsWrapper = styled.div`
   
`
const SocialMediaLink = styled.a`
    font-family: Sanomat Sans Text;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.grey[3]};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    &:hover{
        text-decoration: underline;
    }
`
const Link = styled.a`
    font-family: Sanomat Sans Text;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.grey[3]};
    &:hover{
        text-decoration: underline;
    }
`

const IconWrapper = styled.div`

`

const SocialMediaIcon = styled.img`

`
export {
    Footer,
    FooterColumnWrapper,
    Flex,
    FlexColumn,
    FlexRow,
    Section,
    Text,
    BoldText,
    Headline,
    SocialMediaWrapper,
    SocialMediaLink,
    AppsWrapper,
    IconWrapper,
    SocialMediaIcon,
    Link
}